import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import BannerActions from "../../components/banners/bannerActions"
import Commercial from "../../compon../../components/services/commercial"
import FeaturedProject from "../../components/gallery/featuredProject"
import CallToAction from "../../components/banners/callToAction"
import FeaturedTestimonial from "../../components/testimonials/featuredTestimonial"

import Featured from "../../images/projects/commercial/events-container-panel.jpg"
import Banner from "../../images/banner.png"
import ActionBanner from "../../images/action-banner.png"

export default function CommercialServices() {
  return (
    <Layout>
      <SEO title="Commercial Electrician Toronto and GTA" />
      <BannerActions img={Banner} />
      <Commercial />
      <CallToAction
        img={ActionBanner}
        textPrimary="need a commercial electrician?"
        textSecondary=""
      />
      <FeaturedProject
        title="recent commercial project"
        description="Events Container Panel"
        img={Featured}
        altText="Commercial Electrician Toronto and GTA"
      />
      <FeaturedTestimonial
        testimonial="We have hired Royco Electric to complete some electrical work for multiple projects we are working on. He has worked with us and also directly with our clients. James and his team are always extremely professional and easy to work with. They are respectful of the spaces they work in and are efficient at their job but also take the time to make sure their work is perfect. They trouble shoot problems seamlessly and are always on time. Overall, it is always a pleasure working with Royco Electric."
        company="Harrison Fae Design"
        name="ALICIA MARCHIONI"
      />
    </Layout>
  )
}
