import React from "react"
import { Container, Flex, Section } from "../layoutComponents"
import { List, ImgWrapper } from "./serviceItems"
import Title from "../title"

import CommercialServices from "../../images/services/commercial-services.png"

export default function Commercial(props) {
  return (
    <Section>
      <Container>
        <Title title="Commercial Electrician Toronto and GTA" />
        <Flex>
          <List className="spacing">
            <li>Service upgrades and Maintenance</li>
            <li>New construction and renovations</li>
            <li>Conduit runs, cabling and wiring systems</li>
            <li>Equipment connections and patient care</li>
            <li>Offices, Restaurants, store front, retail and more</li>
          </List>
          <ImgWrapper>
            <img
              src={CommercialServices}
              alt="commercial electrical services ontario"
            />
          </ImgWrapper>
        </Flex>
      </Container>
    </Section>
  )
}
